<template>
  <common-form
          ref="form"
          url="web/app-forum-topic"
          :edit="edit"
          :items="items"
          @success="$emit('success')"></common-form>

</template>

<script>

  export default {
    data () {
      return {
        edit:false
      }
    },
    computed: {
      items() {
        return [
          {
            type: "image",
            name: "图片",
            key: "logo",
          },
          {
            type: "input",
            name: "名称",
            key: "name",
            required: true
          },
          {
            type: "input",
            name: "简介",
            key: "des",
          },
          {
            type: "checkbox",
            name: "是否显示",
            key: "enable",
            hint: "是否在小程序论坛中显示"
          },
          // {
          //   type: "checkbox",
          //   name: "用户发帖",
          //   key: "userAllow",
          //   hint: "是否允许用户在该版块发布帖子"
          // },
          {
            type: "number",
            name: "排序",
            key: "sort",
          },
        ]
      }
    },
    methods: {
      show (model = {}) {
        this.edit = model.id>0
        if(!this.edit){
          model.enable=true
          model.userAllow=true
        }
        this.$refs.form.show(model)
      }
    }

  }
</script>

<style>

</style>
